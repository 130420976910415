import React from 'react';
import Grid from '@mui/material/Grid2';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { styled } from '@mui/system';
import PrivacyPolicyModal from './PrivacyPolicyModal';
import sfondoSmall from './sfondo_small.png';
import divider1 from './divider1.png';
import divider2 from './divider2.png';
import divider3 from './divider3.png';


const targetMail = "info@morkthulhu.com";
const CustomTextField = styled(TextField)({
    '& input': {
        color: 'white',       // Colore del testo
    },
    '& label': {
        color: 'white',       // Colore dell'etichetta
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#F7D97F', // Colore del bordo
        },
        '&:hover fieldset': {
            borderColor: '#F7D97F', // Colore del bordo al passaggio del mouse
        },
        '&.Mui-focused fieldset': {
            borderColor: '#F7D97F', // Colore del bordo quando in focus
        },
    },
    '& .MuiInputLabel-root': {
        '&.Mui-focused': {
            color: 'white',
        },
    },
});

const InterestForm = () => {
    const [email, setEmail] = useState('');
    const [open, setOpen] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!validateEmail(email)) {
            alert('Per favore, inserisci un indirizzo email valido.');
            return;
        }
        const link = "mailto:" + targetMail + "?subject=" + encodeURIComponent("Avvisami quando pubblicherai Morkthulhu!") + "&body=" + encodeURIComponent("Ti lascio la mia mail, avvisami quando Morkthulhu sarà disponibile: " + email);
        window.open(link, "_blank");
    }

    const handleOpen = () => {
        setOpen(true);
    }

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleClose = () => {
        setOpen(false);
    }

    const navigateAppSchede = () => {
        window.location.href = "https://www.morkthulhu.com/appschede";
    }

    const navigateQRCodeSito = () => {
        window.location.href = "https://www.morkthulhu.com/qrsito.png";
    }

    const navigateQRCodeAppSchede = () => {
        window.location.href = "https://www.morkthulhu.com/qrschede.png";
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2} alignItems="center" display="flex" justifyContent="center">
                <Grid item size={12}>&nbsp;</Grid>
                <Grid item size={12}>
                    <img src={sfondoSmall} alt="Morkthulhu" style={{ width: "100%", height: "auto" }} />
                </Grid>
                <Grid item size={12}>&nbsp;</Grid>
                <Grid item size={12}>
                    <img src={divider2} alt="Morkthulhu" style={{ width: "100%", height: "auto" }} />
                </Grid>
                <Grid item size={12}>&nbsp;</Grid>
                <Grid item size={12}>
                    <Typography variant="h4" align="left">
                        Grazie per il tuo interesse!
                    </Typography>
                </Grid>
                <Grid item size={12}>
                    <Typography variant="body1" fontWeight="bold" align="justify">Morkthulhu è ancora in fase di sviluppo.</Typography>
                    <br />
                    <Typography fontStyle="italic" variant="body1" align="justify">
                        Se ti va, quando sarà pronto, poco prima di lanciare il progetto su Kickstarter, ti invieremo un'email per avvisarti.
                    </Typography>
                    <br />
                    <Typography variant="body1" fontWeight="bold" fontStyle="italic" align="justify">
                        Lasciandoci la tua mail non ti impegni a nulla, ma ci aiuterai a capire quanti siamo e a raggiungere più persone possibili.
                    </Typography>
                </Grid>
                <Grid item size={12}>&nbsp;</Grid>
                <Grid item size={12}>
                    <img src={divider1} alt="Morkthulhu" style={{ width: "100%", height: "auto" }} />
                </Grid>
                <Grid item size={12}>&nbsp;</Grid>
                <Grid item size={12}>
                    <Typography variant="h4" align="left">
                        Raccolta di dati personali per la pubblicazione di Morkthulhu
                    </Typography>
                </Grid>
                <Grid item size={12}>
                    <Typography variant="body1" align="justify">
                        Noi raccogliamo i tuoi dati personali, inclusi il tuo indirizzo email, solo con il tuo consenso esplicito.
                    </Typography>
                </Grid>
                <Grid item size={12}>
                    <Typography variant="body1" align="justify">
                        I tuoi dati saranno utilizzati esclusivamente per inviarti informazioni riguardanti il nostro progetto Kickstarter di Morkthulhu e altre comunicazioni correlate.
                    </Typography>
                </Grid>
                <Grid item size={12}>
                    <Typography variant="body1" align="justify">
                        Non condivideremo i tuoi dati con terze parti in alcun modo. Inoltre, puoi revocare il tuo consenso in qualsiasi momento inviando un'email a {targetMail}.
                    </Typography>
                </Grid>
                <Grid item size={12} display="flex" justifyContent="center">
                    <Button variant="text" color="white" onClick={handleOpen}>Mostra la Privacy Policy</Button>
                </Grid>
                <Grid item size={12}>
                    <Typography variant="body1" align="justify">
                        Se riscontri dei problemi nell'invio, puoi spedire il tuo indirizzo mail a &nbsp;
                        <a target='_blank' rel='noreferrer' style={{ color: "white" }} href={"mailto:" + targetMail + "?subject=" + encodeURIComponent("Avvisami quando pubblicherai Morkthulhu!") + "&body=" + encodeURIComponent("Ti lascio la mia mail, avvisami quando Morkthulhu sarà disponibile.")}>{targetMail}</a>.
                    </Typography>
                </Grid>
                <Grid item size={12}>&nbsp;</Grid>
                <Grid item size={{ md: 7, xs: 12 }} display="flex" justifyContent="center">
                    <CustomTextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={!validateEmail(email) && email !== ''}
                        helperText={!validateEmail(email) && email !== '' ? 'Indirizzo email non valido' : ''}
                    />
                </Grid>
                <Grid item size={{ md: 3, xs: 12 }} display="flex" justifyContent="center">
                    <Button type="submit" variant="contained" style={{ backgroundColor: '#F7D97F', color: 'black' }} fullWidth>
                        Invia
                    </Button>
                </Grid>
                <Grid item size={12}>&nbsp;</Grid>
                <Grid item size={12}>
                    <img src={divider3} alt="Morkthulhu" style={{ width: "100%", height: "auto" }} />
                </Grid>
                <Grid item size={12}>&nbsp;</Grid>
                <Grid item size={12}>
                    <Typography variant="body1" align="justify">
                        Qualche link, utile durante le sessioni..
                    </Typography>
                </Grid>
                <Grid item size={12}>&nbsp;</Grid>
                <Grid item size={4}>
                    <Button onClick={navigateAppSchede} variant="text" fullWidth style={{ color: '#F7D97F' }} >
                        App Schede
                    </Button>
                </Grid>
                <Grid item size={4}>
                    <Button onClick={navigateQRCodeSito} variant="text" fullWidth style={{ color: '#F7D97F' }}>
                        QR Code Sito
                    </Button>
                </Grid>
                <Grid item size={4}>
                    <Button onClick={navigateQRCodeAppSchede} variant="text" fullWidth style={{ color: '#F7D97F' }}>
                        QR Code App Schede
                    </Button>
                </Grid>
                <Grid item size={12}>&nbsp;</Grid>
            </Grid>
            <PrivacyPolicyModal open={open} onClose={handleClose} />
        </form >
    );
};

export default InterestForm;