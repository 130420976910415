import './App.css';
import { Container, Box } from '@mui/material';
import { styled } from '@mui/system';
import InterestForm from './InterestForm';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Fab from '@mui/material/Fab';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useEffect, useState } from 'react';

const FloatingFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  top: '50px',
  right: '5%', // Default for mobile
  [theme.breakpoints.up('sm')]: {
    right: '20%',
  },
  [theme.breakpoints.up('md')]: {
    right: '30%',
  },
  backgroundColor: '#F7D97F',
  '&:hover': {
    backgroundColor: '#E5C26D',
  },
  color: 'black',
}));
const ResponsiveBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    width: '95%',
  },
  [theme.breakpoints.up('sm')]: {
    width: '80%',
  },
  [theme.breakpoints.up('lg')]: {
    width: '70%',
  },
}));

function App() {
  const [isBottom, setIsBottom] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY;
      const clientHeight = window.innerHeight;
      setIsBottom(scrollTop + clientHeight >= (scrollHeight - 100));
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClick = () => {
    window.scrollTo({
      top: isBottom ? 0 : document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  return (
    <Container className='App'>
      <ResponsiveBox className='App-header'>
        <FloatingFab aria-label="scroll" onClick={handleClick}>
          {isBottom ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
        </FloatingFab>
        <InterestForm />
      </ResponsiveBox>
    </Container>
  );
}

export default App;
