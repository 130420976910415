import React from 'react';
import { Modal, Box, Typography, Button, Fade } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function PrivacyPolicyModal(props) {

    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
            closeAfterTransition
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
        >
            <Fade in={props.open}>
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Privacy Policy
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Gli indirizzi email raccolti saranno raccolti nella Vault di OneDrive 365 
                        degli autori. La Vault è protetta da una verifica addizionale dell'identità e viene
                        bloccata dopo l'aggiunta di un indirizzo email.
                        In caso di revoca del consenso, l'indirizzo email verrà rimosso dalla Vault.
                        In caso di violazione della sicurezza, gli autori si impegnano a notificare
                        l'utente entro 72 ore dalla scoperta della violazione.
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={props.onClose}
                        sx={{ mt: 2 }}
                    >
                        Chiudi
                    </Button>
                </Box>
            </Fade>
        </Modal>
    );
}

export default PrivacyPolicyModal;
